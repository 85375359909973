import React from 'react'
import Figure1 from '../../images/Figure_1.png'

export default function HowToSetup() {

  // function which posts the example data to users dweet
  function postData() {
    const data = {
      Vt : 24.0,
      V1 : 12.0,
      A : 18.2,
      RPM : 1200,
      Spd : 6.2,
      Tmp1 : 24.0,
      Tmp2 : 24.0,
      AH : 6.2,
      Lap : 9,
      Gear : 7,
      Brk : 100,
      Lon : 52.0,
      Lat : -2.0
    }
    fetch('https://dweet.io/dweet/for/dashowltest', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      mode: 'no-cors'
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }




  return (
    <>

<div class="container my-5" id="featuredemo1">
    <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
      <div class="col-lg-7 p-3 p-lg-5 pt-lg-3">
        <h1 class="display-4 fw-bold lh-1" >Connect your <span class="gradient-text1">Data</span> with ease</h1>
        <br></br>
        <p className="lead">Follow this 3 step guide to access your telemetry data in 1 minute.</p>
        <ol className="lead">
          <li>Create a JSON object with your telemetry data</li>
          <li className="my-2"><code className="">POST</code> with your telemetry data in the body of the request to <a href="https://dweet.io/">your Dweet Thing</a></li>
          <li>Create a DashOwl account and enter your Dweet Thing name when prompted to setup (you can change this at any point in the settings)</li>
        </ol>
        <br></br>
        <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
          <a href="https://app.dashowl.co.uk/register" class="btn btn-dark btn-lg px-4 me-md-2">Start Now</a>
          <a href="https://dweet.io/play/#!/dweets/postDweet_post_0" target="_blank" className="btn btn-outline-dark btn-lg">Create Test Data</a>
        </div>
        <small className="">Use our example telemetry object to create test data for your dashboard</small>
      </div>
      <div class="col-lg-4 p-0 d-flex">
        <pre className="mx-auto card py-4 px-5">
          <h5>Telemetry Object</h5>
        <code className="fs-6">
          { "{" }
            <br></br>
          <span> "Vt" : 24.0,</span><br></br>
          <span> "V1" : 12.0,</span><br></br>
          <span> "A" : 18.2,</span><br></br>
          <span> "RPM" : 1200,</span><br></br>
          <span> "Spd" : 6.2,</span><br></br>
          <span> "Tmp1" : 24.0,</span><br></br>
          <span> "Tmp2" : 24.0,</span><br></br>
          <span> "AH" : 6.2,</span><br></br>
          <span> "Lap" : 9,</span><br></br>
          <span> "Gear" : 7,</span><br></br>
          <span> "Brk" : 100,</span><br></br>
          <span> "Lon" : -4,</span><br></br>
          <span> "Lat" : 56</span><br></br>
          { "}" }
        </code>
       
        </pre>
        
      </div>
    </div>
  </div>
</>
  )
}
/*
   Vt : 24.0,
    V1 : 12.0,
    A : 18.2,
    RPM : 1200,
    Spd : 6.2,
    Tmp1 : 24.0,
    Tmp2 : 24.0,
    AH : 6.2,
    Lap : 9,
    Gear : 7, 
    Brk : 100,
    Lon : 52.0,
    Lat : -2.0
} */