import MenuBar from './pages/components/NavBar';
import './App.css';
import mainImage from './images/ef2023dashowldemo2.png'

import EmailForm from './pages/components/EmailForm';

import Footer from './pages/components/Footer';
import StartNowButton from './pages/components/StartNowButton';
import HowToSetup from './pages/components/HowToSetup';
import FeatureDemo1 from './pages/components/FeatureDemo2';
import FeatureDemo2 from './pages/components/FeatureDemo1';
import FeatureDemo3 from './pages/components/FeatureDemo3';
import ComparePlans from './pages/components/ComparePlans';
import Pricing from './pages/components/Pricing';
import Features from './pages/components/Features';
import EchookLogfileVis from './pages/components/EchookLogfileVis';
import ContactForm from './pages/components/ContactForm';
import FeaturedUsers from './pages/components/FeaturedUsers';


function App() {
  return (
    <>
    <MenuBar />
    <div class="container col-xxl-8 px-4 py-5">
    <div class="row flex-lg-row-reverse align-items-center g-5 py-2">
      <div class="col-10 col-sm-8 col-lg-6 m-auto">
      <h1 class="display-2 fw-bold lh-1 mb-3 pt-2 text-center">Your <span class="home-page-highlight">real-time</span> data pit crew.</h1>
        <p class="lead text-center">Make informed decisions on the track by monitoring real-time data. Use data driven insights to make adjustments on the fly and push your car to the limits.</p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-around">
          <StartNowButton />
          </div>
        
      </div>
      <div class="col-lg-6 m-auto mt-3 px-5">
      <img src={mainImage} className="d-block mx-lg-auto mx-auto img-fluid" alt="Product" loading="lazy"/>
      </div>
    </div>
  </div>
    <FeaturedUsers />
    <HowToSetup />
    <EchookLogfileVis />
    <ContactForm />
    <Footer />
    </>
  );
}

export default App;

/*
<Features />
    <FeatureDemo1 />
    <FeatureDemo2 />
    <FeatureDemo3 />
    <ComparePlans />
    <Pricing />
  
    */
/* <button type="button" class="btn btn-primary btn-lg px-4 me-md-2">Sign up</button>
          <a href="https://dash.green-arrows.co.uk/"><button type="button" class="btn btn-outline-secondary btn-lg px-4">Demo</button></a>
      */