import React from 'react'
import VisualiserScreenshot from '../../images/visualiserScreenshot.png'

export default function EchookLogfileVis() {
  return (
    <>

    <div class="container my-5" id="featuredemo1">
        <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
          <div class="col-lg-7 p-3 p-lg-5 pt-lg-3">
            <h1 class="display-4 fw-bold lh-1" >Want to analyse existing <span class="gradient-text1">Data</span>?</h1>
            <br></br>
            <p className="lead">Use this free tool to instantly visualise eChook logfiles. Just upload your .csv logfile.</p>
            <p className="lead">Scroll through the race to see the cars telemetry at any time, analyse trends by graphing data, and automatically calculate lap times and driver averages.</p>
            <p className="lead">Easily understand whats actually happening to your car during a race.</p>
            <br></br>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
              <a href="https://vis.dashowl.co.uk/get-access" class="btn btn-dark btn-lg px-4 me-md-2">Logfile Visualiser</a>
              {/*<button className="btn btn-outline-dark btn-lg">Read More</button>*/}
            </div>
          </div>
          <div class="col-lg-4 p-0 d-flex">
            <img src={VisualiserScreenshot} className="img-fluid"/>
          </div>
        </div>
      </div>
    </>
  )
}
