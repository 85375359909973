import React from 'react'
import GreenArrowsLogo from '../../images/GreenArrowsLogo.jpg'


export default function FeaturedUsers() {
  return (
    <>
    <div class="container-fluid shadow my-0">  
        <div class="container px-4 py-3 my-0" id="hanging-icons">
            <p className="text-center lead">Used by</p>
            <div class="row g-4 row-cols-1 row-cols-lg-3 pt-4">
                
                <div class="col d-flex text-center py-0 my-0 w-100">
                    
                    <div className="mx-auto" >
                        <h3 class="p-4" style={{backgroundImage:`url(${GreenArrowsLogo})`, backgroundSize:"contain", backgroundRepeat:"no-repeat",backgroundPositionX:"center", backgroundPositionY:"center",opacity:"1"}}></h3>
                        <h3>Green Arrows<p className="fs-6 fw-normal">(1st Kit Car 2023)</p></h3>
                    </div>
                    

                </div>
            </div>
        </div>
    </div>
    
    </>
  )
}
