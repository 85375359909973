import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Thanks from './pages/Thanks';

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";


import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import DocsOverview from './pages/docs/DocsOverview';
import DocsSetupCar from './pages/docs/SetupCar';
import DocTemplate from './pages/docs/DocTemplate';
import DocsGetData from './pages/docs/GetData';


import NoPage from './pages/NoPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename="/">
    <Routes>
      <Route index element={<App />}/>

      <Route path="/thanks" element={<Thanks />}/>
      <Route path="/docs" element={<DocsOverview />}/>
      <Route path="/docs/setup-car" element={<DocTemplate><DocsSetupCar /></DocTemplate>}/>
      <Route path="/docs/get-data" element={<DocTemplate><DocsGetData /></DocTemplate>}/>
      <Route path="*" element={<NoPage />}/>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//<React.StrictMode>
//<App />
//</React.StrictMode>