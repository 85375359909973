// Page to thank user for submitting their email
// Links to go back to site or to check out the visualiser

import React from 'react'
import MenuBar from './components/NavBar'
import { Link } from 'react-router-dom'
import VisualiserImg from '../images/VisualiserScreenshot.jpg'
import Footer from './components/Footer'

export default function Thanks() {
  return (
    <>
    <MenuBar />
    <div class="px-4 pt-5 my-5 text-center border-bottom shadow">
    <h1 class="display-4 fw-bold">Thanks!</h1>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">We will be in touch as soon as accounts become available for our live dashboard. In the meantime, try our free logfile visualiser below.</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <a href="https://vis.dashowl.co.uk/get-access"><button type="button" class="btn btn-primary btn-lg px-4 me-sm-3">Free Logfile Visualiser</button></a>
        <Link to="/"><button type="button" class="btn btn-outline-secondary btn-lg px-4">Back</button></Link>
      </div>
    </div>
    <div class="overflow-hidden thanks">
      <div class="container px-5">
      <img src={VisualiserImg} class="img-fluid border rounded-3 shadow-lg mb-4" alt="Example" width="900" height="500" loading="lazy" />
      </div>
    </div>
  </div>
  <div class="thanks-spacer"></div>
  <Footer />
  </>
  )
}
