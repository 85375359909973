import React from 'react'

export default function Footer() {
  return (
    <div class="container">
      <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <div class="col-md-4 d-flex align-items-center">
            <span class="text-muted">DashOwl</span> 
            
        </div>
        <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li class="ms-3"><a class="footer-links text-muted" href="https://www.buymeacoffee.com/alanjsfleming">Buy Me a Coffee</a></li>
          
        </ul>
  </footer>
</div>
  )
}

//<a class="text-muted" href="#">
//<a class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">Telemetry</a>
/*
<li class="ms-3">Twitter</li>
      <li class="ms-3">Instagram</li>
      */