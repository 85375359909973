import React from 'react'

export default function ContactForm() {
  return (
    <div class="container my-5" id="featuredemo1">
        <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
            <div className="w-75 mx-auto pb-3">
                <h1 className="display-4 fw-bold lh-1">Contact</h1>
                <br></br>
                <p className="lead">Don't hesitate to reach out with any questions, suggestions/requests or for any support!</p>
                <form action="https://formsubmit.co/alanjsfleming5@gmail.com" method="POST">
                    <input type="hidden" name="_subject" value="DashOwl new submission!" />
                    <div className="form-group my-3">
                        <label className="form-label">Email Address</label>
                        <input className="form-control" type="email" name="email" placeholder="Email Address" required />
                    </div>

                    <div className="form-group my-3">
                        <label className="form-label">Message:</label>
                        <textarea placeholder="Your message" className="form-control" rows="4" type="text" name="message" required />
                    </div>   

                    <button className="btn btn-outline-dark w-100 btn-lg" type="submit">Send</button>
                    
                </form>
        
                </div>
        </div>
    </div>
  )
}
