import React from 'react'
import { Link } from 'react-router-dom'

export default function SetupCar() {
  return (
    <>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><Link to="/docs">Docs</Link></li>
          <li class="breadcrumb-item active" aria-current="page">Set Up Your Car</li>
        </ol>
      </nav>
        <h1>Set Up Your Car</h1>
        <p>The available car settings are listed below with a guide on how to choose the right value for your car.</p>
        
        
        <h3>Contents</h3>
        <ul>
            <li><a href="#carname">Car Name</a></li>
            <li><a href="#dweetthingname">Dweet Thing Name</a></li>
            <li><a href="#batterycapacity">Battery Capacity (Ah)</a></li>
            <li><a href="#reversegearingmode">Reverse Gearing Mode</a></li>
            <li><a href="#motorgearteeth">Number of teeth on motor gear</a></li>
            <li><a href="#axlegearteeth">Number of teeth on axle gear</a></li>
            <li><a href="#gearnumberoffset">Gear Number Offset</a></li>
            <li><a href="#batteryoffset">Battery Offset</a></li>
        </ul>
        <hr></hr>
        <h3 id="carname">Car Name</h3>
        <p>The name of the car. This is just used for display purposes so this can be anything you choose.</p>
        <hr></hr>
        <h3>Dweet Thing Name</h3>
        <p>The name of your Dweet Thing is related to the URL you are posting your data to. For a URL of:</p>
        <code>https://dweet.io/dweet/for/<mark>my-car-name</mark></code>
        <br></br>
        <br></br>
        <p>the Dweet Thing name would be <code><mark>my-car-name</mark></code>.</p>
        <p>Care should be taken to choose your Dweet Thing name so that it is not a common word or other people may be posting to the same Thing.</p>
        <hr></hr>
        <h3>Battery Capacity (Ah)</h3>
        <p>The total capacity of your car's batteries, in Amp Hours.</p>
        <p>This is used to calculate the % of battery remaining during a race, so you can either find the rating of your batteries, or just set this to an estimated value you would like to aim for. By default, this is set to 25, however determining the real rating of the batteries you are using is recommended.</p>
        <p>If you are measuring your batteries, the formula for find the Amp hour rating is:</p>
        <code>Amp Hour = Current * Discharge Time</code>
        <br></br>
        <br></br>
        <p>If the car is fitted with a current meter, some meters will output an Amp hour value which you can set to 0 before a race and use your final value as the rating of your batteries.</p>
        <hr></hr>
        <h3>Reverse Gearing Mode</h3>
        <p>If Reverse Gearing Mode is set to No, the Gear value posted to the car's Dweet Thing will be used to display the current gear.</p>
        <p>Some cars gearing is incorrectly calculated/difficult to setup within the eChook app. Setting Reverse Gearing Mode to Yes will use the user inputted values for Number of teeth of motor gear (Driver Gear) and Number of teeth on axle gear (Driven Gear) to calculate the current gear number within DashOwl</p>
        <hr></hr>
        <h3>Number of teeth on motor gear</h3>
        <p>The number of teeth on the motor gear (Driver Gear) of your car.</p>
        <p>This is used to calculate the current gear number if Reverse Gearing Mode is set to Yes.</p>
        <hr></hr>
        <h3>Number of teeth on axle gear</h3>
        <p>The number of teeth on the axle gear (Driven Gear) of your car.</p>
        <p>This is used to calculate the current gear number if Reverse Gearing Mode is set to Yes.</p>
        <hr></hr>
        <h3>Gear Number Offset</h3>
        <p>The gear number offset is used to offset the gear number if Reverse Gearing Mode is set to Yes. This should be set to any number.</p>
        <p>Because each car is slightly different, some car's gear number can be incorrectly calculated, use this setting to adjust the calculated output to the true selected gear numeber.</p>
        <hr></hr>
        <h3>Battery Offset</h3>
        <p>The battery offset is used to offset the battery percentage if the battery percentage is not being calculated correctly.</p>
        <p>This could happen for a number of reasons, for example if your telemetry is accidently reset during a race. This can help compensate for these issues without bringing in the car.</p>
    </>
  )
}
