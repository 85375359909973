import React, {useState} from 'react'
import { Link } from 'react-router-dom'

export default function GetData() {

    const [language, setLanguage] = useState("javascript");

  return (
    <>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><Link to="/docs">Docs</Link></li>
          <li class="breadcrumb-item active" aria-current="page">Getting Car Data</li>
        </ol>
      </nav>
    <h1>Getting Car Data</h1>
    <p>DashOwl uses Dweet to receive the car's data.</p>
    <p>You can use an <a href="https://github.com/echook">eChook board</a> to collect measurements from your car and the <a href="https://play.google.com/store/apps/details?id=com.ben.drivenbluetooth&pli=1">eChook app</a> or the <a href="https://play.google.com/store/apps/details?id=net.keduro.omni">OMNI telemetry app</a> to connect with bluetooth and relay the measurements to your Dweet Thing.</p>
    
    <h5>Choosing a Dweet Thing name</h5>
    <p>The name of your Dweet Thing is related to the URL you are posting your data to. For a URL of:</p>
    <code>https://dweet.io/dweet/for/<mark>my-car-name</mark></code>
    <br></br>
    <br></br>
    <p>the Dweet Thing name would be <code><mark>my-car-name</mark></code>.</p>
    <p>Care should be taken to choose your Dweet Thing name so that it is not a common word or other people may be posting to the same Thing.</p>
    <hr></hr>
    <h5>Posting data to Dweet</h5>
    <p>If you are using one of the apps recommended above, you can start sending data simply by setting your Dweet name within the app's settings.</p>
    <p>If you prefer to send data to Dweet with your own implementation, you can make a POST request to <code>https://dweet.io/dweet/for/<mark>my-car-name</mark></code>, with the JSON of that data in the body of the request.</p>
    <p>Below is an example of how this can be done!</p>
    <div className="btn-group">
        <button onClick={()=>{setLanguage("javascript")}} className={"btn btn-outline-dark "+((language==="javascript") && " active")}>JavaScript</button>
        <button onClick={()=>{setLanguage("python")}} className={"btn btn-outline-dark "+((language=="python") && " active")}>Python</button>
    </div>
    {(language==="javascript") && <code className="card p-3">
        <span>const postData = async(url,data) {"=>{"}</span>
        <span> try {"{"}</span>
        <span> const response = await fetch(url, {"{"}</span>
        <span> method: 'POST',</span>
        <span> headers: {"{"}</span>
        <span> 'Content-Type': 'application/json'</span>
        <span> {"}"},</span>
        <span> body: JSON.stringify(data),</span>
        <span> {"});"}</span>
        <br></br>
        <span> const jsonReponse = await reponse.json()</span>
        <br></br>
        <span> console.log('Success:', jsonReponse);</span>
        <span>{"}"}</span>
        <br></br>
        <span>catch (error) {"{"}</span>
        <span>console.error('Error:', error);</span>
        <span>{"}"}</span>
        <span>{"}"}</span>
        <br></br>

        <span>const url = 'https://dweet.io/dweet/for/my-car-name'</span>
        <br></br>
        <span>const data = {"{"}</span>
          <span> "Vt" : 24.0,</span>
          <span> "V1" : 12.0,</span>
          <span> "A" : 18.2,</span>
          <span> "RPM" : 1200,</span>
          <span> "Spd" : 6.2,</span>
          <span> "Tmp1" : 24.0,</span>
          <span> "Tmp2" : 24.0,</span>
          <span> "AH" : 6.2,</span>
          <span> "Lap" : 9,</span>
          <span> "Gear" : 7,</span>
          <span> "Brk" : 100,</span>
          <span> "Lon" : -4,</span>
          <span> "Lat" : 56</span>
          { "}" }

        <br></br>
        <span>postData(url,data)</span>
    </code>}


    {(language==="python") && <><code className="card p-3">
        <span>import requests</span>
        <br></br>
        <span>url = 'https://dweet.io/dweet/for/my-car-name'</span>
        <br></br>
        <span>data = {"{"}</span>
          <span> "Vt" : 24.0,</span>
          <span> "V1" : 12.0,</span>
          <span> "A" : 18.2,</span>
          <span> "RPM" : 1200,</span>
          <span> "Spd" : 6.2,</span>
          <span> "Tmp1" : 24.0,</span>
          <span> "Tmp2" : 24.0,</span>
          <span> "AH" : 6.2,</span>
          <span> "Lap" : 9,</span>
          <span> "Gear" : 7,</span>
          <span> "Brk" : 100,</span>
          <span> "Lon" : -4,</span>
          <span> "Lat" : 56</span>
          { "}" }
        <br></br>
        <span>response = requests.post(url, json=data)</span>
    </code>
        <p>You may need to <code>pip install requests</code> if requests library is not installed.</p>
    </>
        }

    </>
  )
}
