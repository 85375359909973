import React from 'react'
import { Link } from 'react-router-dom'
import Emoji from './Emoji'

export default function MenuBar() {
  return (
    <nav class="navbar navbar-expand-md navbar-light bg-light mb-4">
        <div class="container-fluid">
            <Link to="/"><a class="navbar-brand underline" href="#"><Emoji symbol="🦉" label="owl" />DashOwl</a></Link>
            
        
            <div class="d-flex">
              <div className="navbar-nav mx-3">
                <Link to="/docs" className="nav-link">Docs</Link>
  
              </div>
                <a href="https://app.dashowl.co.uk/"><button class="btn btn-dark mx-1">Log in</button></a>
            </div>
        
        </div>
    </nav>
  )
}
