import React from 'react'
import MenuBar from '../components/NavBar'
import Footer from '../components/Footer'

export default function DocTemplate({children}) {
  return (
    <>
        <MenuBar />
        <div className="container">
            {children}
        </div>
        <Footer />
    </>
  )
}
