import React from 'react'

export default function StartNowButton() {
  return (
    <>
    <div className="d-flex w-100">
    <a hidden href="https://www.app.dashowl.co.uk" id="startNowButton" className="btn btn-outline-dark mx-1 w-50 fs-4 p-2">
        Login
    </a>

    <a href="https://www.app.dashowl.co.uk/register" id="startNowButton" className="btn btn-dark w-100 mx-1 fs-4 p-2">
        Start Now
    </a>
    </div>
    </>
  )
}
