import React from 'react'
import MenuBar from '../components/NavBar'
import { Link } from 'react-router-dom'

export default function DocsOverview() {
  return (
    <>
        <MenuBar />
        <div className="container">

            <h1>Documentation</h1>
            <p>Here you can find all the documentation you need to get started with DashOwl.</p>
            <br></br>
            <h2>Getting Started</h2>
            <p>Follow these articles to get your dashboard fully set up and customised:</p>
            <ol>
                <li><Link to="/docs/get-data">Get your car's data on DashOwl</Link></li>
                <li><Link to="/docs/setup-car">Set up your Car</Link></li>
            </ol>
        
        
        
        
        </div>
        
        
    </>
  )
}
